<template>
  <div class="invoiceModal">
    <a-modal :width="606" :visible="ifHistory" :closable="false">
      <template #footer></template>
      <div class="center">
        <div class="info">
          <div class="infotop">
            <span>历史成绩</span>
          </div>
          <div class="infocentent" v-if="total">
            <div class="imgtxt" v-for="(item, index) in infoList" :key="index">
              <img src="@/assets/image/study/Group5763.png" alt="" />
              <span
                >总成绩：
                <p class="a1">{{ item.totalScore }}</p>
                <p class="a2">分</p></span
              >
              <span
                >考试成绩：
                <p class="a1">{{ item.examScore }}</p>
                <p class="a2">分</p></span
              >
              <div class="borleft">{{ item.examDate }}</div>
            </div>
          </div>
          <span v-else>暂无数据</span>
        </div>
        <div class="infotop" v-if="total > 10">
          <a-pagination
            v-model="current"
            @change="selchange"
            :total="total"
            show-less-items
          />
        </div>
        <div class="box_button">
          <div class="all-btn-small" @click="onConfirm()">我知道了</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: [
    "ifHistory", // 控制弹窗显示/隐藏  必填
    "courseId", //课程id
  ],
  // 数据对象
  data() {
    return {
      infoList: [],
      infoData: {},
      current: 1,
      total: 0,
    };
  },
  // 事件处理器
  methods: {
    selchange(e) {
      console.log(e);
    },
    // 获取历史成绩
    getInfo(e) {
      this.$ajax({
        url: "/hxclass-pc/exam/study/record",
        params: {
          courseId: e,
          userId: this.$store.state.userInfo.userId, //用户id
          current: this.current,
          size: 10,
        },
      }).then((res) => {
        this.loadingInfo = false;
        this.infoList = res.data.records;
        this.total = res.data.total;
      });
    },
    // 抬头类型
    changeType(index) {
      this.headerType = index;
    },
    // 保存
    onConfirm(e) {
      this.current = 1;
      this.$emit("onino");
    },
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    courseId(newval) {
      this.getInfo(this.courseId);
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
/deep/.ant-modal-footer {
  display: none;
}
/deep/.ant-modal-body {
  padding: 0px !important;
}
.infotop {
  margin-bottom: 38px;
  margin-top: 32px;
  text-align: center;
  span {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 27px;
  }
}
.center {
  width: 100%;
  background: #ffffff;
  overflow: hidden;
  padding-bottom: 34px;
  border-radius: 8px 8px 8px 8px;
  .info {
    width: 100%;
    padding: 0 20px;
    text-align: center;
    .infocentent {
      background: #ffffff;
      padding-left: 85px;
      border-radius: 4px 4px 4px 4px;
      .imgtxt {
        display: flex;
        align-items: center;
        img {
          width: 14px;
          margin-right: 3px;
        }
        span {
          display: block;
          width: 120px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
          display: flex;
          align-items: center;
        }
        .a1 {
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #15b7dd;
        }
        .a2 {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #15b7dd;
        }
      }
      .borleft {
        border-left: 1px solid rgba(0, 0, 0, 0.5);
        padding-left: 18px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.box_button {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  padding: 0 40px;
  .all-btn-blue,
  .all-btn-small {
    font-size: 16px;
  }
}
.pick {
  span {
    color: #ffffff !important;
  }
  background: #1cb9de;
}
</style>
