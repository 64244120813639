<template>
  <!-- 添加发票抬头/换开发票抬头 -->
  <div class="invoiceModal">
    <a-modal :width="422" :visible="isAddInvoiceModal" :closable="false">
      <template #footer></template>
      <div class="center">
        <div class="blue"></div>
        <div class="info">
          <div class="infotop">
            <span>更换班级</span
            ><img src="@/assets/image/study/Vector.png" alt="" />
          </div>
          <div class="infocentent">
            <div class="imgtxt">
              <img src="@/assets/image/study/Group299.png" alt="" />
              <span>当前班级期数</span>
            </div>
            <p>
              {{ infoData.className }} {{ infoData.startTime }}-{{
                infoData.endTime
              }}
            </p>
          </div>
          <div class="infocentent">
            <div class="imgtxt">
              <img src="@/assets/image/study/Group299.png" alt="" />
              <span>以下是可更换的班级期数</span>
              <div class="list">
                <div
                  class="item"
                  :class="{ pick: openTimeId == item.openTimeId }"
                  v-for="(item, index) in infoList"
                  :key="index"
                  @click="onSelect(item)"
                >
                  <span class="span1">{{ item.className }}</span>
                  <span class="span2"
                    >{{ item.startTime }}-{{ item.endTime }}</span
                  >
                  <span class="span3"
                    >剩余：{{
                      item.currentNumber ? item.currentNumber : 0
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box_button">
          <div class="all-btn-blue" @click="onCancel()">取消</div>
          <div class="all-btn-small" @click="onConfirm()">确认更换</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: [
    "isAddInvoiceModal", // 控制弹窗显示/隐藏  必填
    "invoiceType", //发票类型 1添加发票 2换开发票
    "courseId", //课程id
    "openTimeId", //班级id
  ],
  // 数据对象
  data() {
    return {
      infoList: [],
      infoData: {},
      isLoading:false
    };
  },
  // 事件处理器
  methods: {
    // 获取课程班期
    getInfo(e) {
      this.$ajax({
        url: "/hxclass-pc/course/course-open-time/by-id",
        params: {
          courseId: e,
        },
      }).then((res) => {
        this.loadingInfo = false;
        if (res.code == 200 && res.success) {
          for (let index = 0; index < res.data.length; index++) {
            if (res.data[index].openTimeId === this.openTimeId) {
              this.infoData = res.data[index];
            }
          }
          this.infoList = res.data;
          this.infoList.splice(res.data.indexOf(this.infoData), 1);
          console.log(this.infoList);
        }
      });
    },
    // 抬头类型
    changeType(index) {
      this.headerType = index;
    },
    // 取消
    onCancel(e) {
      this.$emit("onCancelAdd");
    },
    // 更换班级点击
    onSelect(e) {
      if (e.currentNumber) {
        this.openTimeId = e.openTimeId;
      } else {
        this.$message.error("名额剩余为 0，无法选择该班级");
      }
    },
    // 保存
    onConfirm(e) {
      // 换班
      if (this.infoData.openTimeId == this.openTimeId) {
        this.$message.warning("您当前已在该班级,无需更换");
      } else {
        if(this.isLoading){return}
        this.isLoading = true;
        this.$ajax({
          url: "/hxclass-pc/course/change-class",
          method: "PUT",
          params: {
            courseId: this.$route.query.courseId,
            openTimeId: this.openTimeId,
          },
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$message.success("更换成功");
          } else {
            this.$message.error(res.message);
          }
          this.isLoading = false;
        });
      }

      this.$emit("onConfirmAdd");
    },
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    courseId(newval) {
      this.getInfo(this.courseId);
    },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
/deep/.ant-modal-footer {
  display: none;
}
/deep/.ant-modal-body {
  padding: 0px !important;
}
.center {
  width: 100%;
  background: #f6f6fc;
  overflow: hidden;
  padding-bottom: 34px;

  border: 1px solid #15b7dd;
  .blue {
    width: 100%;
    height: 8px;
    background: #15b7dd;
    border-radius: 0px 0px 0px 0px;
  }
  .info {
    width: 100%;
    padding: 0 20px;
    .infotop {
      border-bottom: 1px solid #15b7dd;
      display: flex;
      margin-bottom: 11px;
      margin-top: 24px;
      justify-content: space-between;
      span {
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 27px;
      }
      img {
        width: 20px;
        height: 20px;
        position: relative;
        top: 8px;
        right: -4px;
      }
    }
    .infocentent {
      width: 374px;
      background: #ffffff;
      padding: 15px;
      margin-bottom: 24px;
      border-radius: 4px 4px 4px 4px;
      .imgtxt {
        width: 100%;

        span {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #333333;
        }
        img {
          width: 16px;
          margin-right: 4px;
        }
      }
      p {
        margin-top: 8px;
        margin-left: 20px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 21px;
      }
      .list {
        margin-top: 14px;
        display: flex;
        word-break: normal;
        word-wrap: break-word;
        flex-wrap: wrap;
        cursor: pointer;
        .item:nth-child(2n) {
          margin-left: 14px;
        }
        .item {
          width: 160px;
          border-radius: 5px 5px 5px 5px;
          opacity: 1;
          border: 1px solid #8adbee;
          text-align: center;
          padding: 10px 5px;
          margin-bottom: 16px;
          .span1 {
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 21px;
            display: block;
          }
          .span2 {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 21px;
            display: block;
          }
          .span3 {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 21px;
            display: block;
          }
        }
      }
    }
  }
}

.box_button {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  padding: 0 40px;
  .all-btn-blue,
  .all-btn-small {
    font-size: 16px;
  }
}
.pick {
  span {
    color: #ffffff !important;
  }
  background: #1cb9de;
}
</style>
